@use "sass:map";

.resize-area {
	&-container {
		position: absolute;
		inset-block: 0;
		inset-inline-end: 0;
		z-index: map.get($z-indexes, 'up'); // Because filter toolbar has `.z-up` helper
		inline-size: 0;
		overflow: visible;
	}

	&-button {
		--grip-size: 8px;
		--indicator-size: 2px;

		position: absolute;
		inset-block: 0;
		inset-inline-end: calc((var(--scrollbar-width, 0) * 1px) - (var(--grip-size) / 2));
		inline-size: var(--grip-size);

		&::before {
			content: '';
			position: absolute;
			inset-block: 0;
			inset-inline-start: calc((var(--grip-size) - var(--indicator-size)) / 2);
			inline-size: var(--indicator-size);
			background-color: transparent;
			transition: background-color 0.25s easing(ease-out-quint);
		}

		&:hover::before {
			background-color: var(--interaction-norm-hover);
			transition-delay: 0.25s;
		}

		// Separate this to make the other states working on Safari -_-v
		&:focus-visible::before {
			background-color: var(--interaction-norm-hover);
			transition-delay: 0.25s;
		}

		&:active::before {
			background-color: var(--interaction-norm-active);
			transition-delay: 0;
			transition-duration: 0.1s;
		}
	}
}

@import '~@proton/styles/scss/lib';

.message-attachments {
	font-size: rem(14);
}

.message-attachmentList {
	display: grid;
	gap: var(--space-2);
	grid-template-columns: repeat(auto-fill, minmax(max(#{rem(190)}, 20%), 1fr));
}

.message-attachmentPrimaryAction {
	border-start-end-radius: 0;
	border-end-end-radius: 0;

	&::after {
		border-start-end-radius: var(--border-radius-md) !important;
		border-end-end-radius: var(--border-radius-md) !important;
	}
}

.message-attachmentSecondaryAction {
	transition:
		0.15s easing(ease-out-quint),
		background-position 0s;
	background-color: transparent;
	min-inline-size: rem(36);
	align-content: flex-end;

	svg[aria-busy='true'] {
		background-size: 16px;
	}

	&::after {
		background-color: transparent !important;
	}

	&-download {
		position: absolute;
		inset-block: 0;
		inset-inline-end: 0;
		inline-size: rem(36);
		visibility: hidden; // transition to work
		border-start-end-radius: var(--border-radius-md);
		border-end-end-radius: var(--border-radius-md);
	}

	&-size {
		padding-block-end: rem(0.6);
	}

	&:hover,
	&:focus,
	&:focus-within,
	&[aria-busy='true'] {
		background-color: transparent;

		.message-attachmentSecondaryAction-size {
			visibility: hidden;
		}

		.message-attachmentSecondaryAction-download {
			visibility: visible;
			background-color: var(--interaction-default-hover);
		}
	}
}

.message-attachment {
	line-height: 1.3;
	padding: 0;
}

.message-attachmentList-item-container {
	inline-size: 100%;

	&:only-child {
		grid-column-end: 2 span;
		inline-size: fit-content;
		max-inline-size: 100%;
	}
}

.message-attachmentList-item {
	background-color: var(--background-norm);

	&--uploadInProgress {
		background-color: var(--background-weak);

		.message-attachmentSecondaryAction {
			border: 0;
		}
	}

	&--loading {
		background-color: var(--background-weak);
	}

	&--uploading {
		.message-attachmentSecondaryAction-size {
			visibility: hidden;
		}

		.message-attachmentSecondaryAction-download {
			visibility: visible;
		}
	}
}

$message-attachment-icon-width: 2.6em !default;

.message-attachmentIcon {
	inline-size: $message-attachment-icon-width;
}

.message-attachmentInfo {
	border-inline-start: 1px solid var(--border-norm);
	padding-block: 0.3em;
	padding-inline: 0.7em;
	max-inline-size: calc(100% - #{$message-attachment-icon-width});
}
